.tab-space .MuiTabs-flexContainer{
  gap: 20px;
}

.myChartDiv {
  /* width: 300px;
  height: 300px; */
  width: 220px;
}

.total-cell {
  background-color: #f6faff !important;
  font-weight: 700;
}